import type { ValuesByLocale } from '~/i18n/config'

export const LINKEDIN_URL = 'https://www.linkedin.com/company/zoihq'
export const INSTAGRAM_URL = 'https://www.instagram.com/zoivendome'

export const PRESS_EMAIL = 'presse@zoi.com'
export const PRIVACY_EMAIL = 'privacy@zoi.com'

export const PRIVACY_NOTICE_URL: ValuesByLocale<string> = {
  fr: 'https://legal.zoi.com/fr-fr/privacy',
  en: 'https://legal.zoi.com/en-us/privacy'
}

export const TERMS_OF_SERVICES_URL: ValuesByLocale<string> = {
  fr: 'https://legal.zoi.com/fr-fr/terms-of-services',
  en: 'https://legal.zoi.com/en-us/terms-of-services'
}
